import React, {useEffect, useState} from 'react';
import Axios from "axios"
import {useHistory} from 'react-router-dom';
import {mainUrl,webUrl} from "../../api/axios";
import "./../../assets/css/bootstrap.min.css"
import "./Login.css"
import toast, { Toaster } from 'react-hot-toast';


import cms from "./../../assets/img/team.png";
import UNICEF from "./../../assets/img/unicepnglog.png";
import WADAN from "./../../assets/img/wadan.png";
import ARIADELTA from "./../../assets/img/aria_delta.png";

function Login() {
    const [data, setData] = useState({
        email: "",
        password: ""
    })
    useEffect(() => {
        sessionStorage.setItem('dir',"english");
    }, []);

    function handle(e) {
        const newdata = {...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    const history = useHistory();

    function submit(e) {
        e.preventDefault();
        const {result}=  Axios.post(mainUrl + 'login', {
            email: data.email,
            password: data.password,
        }, {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Header': '*'
        }).then(res => {
            notifySuccess(res.data.message);
            setTimeout(()=>{
                sessionStorage.setItem("token", res.data.token);
                return history.push('/dashboard');
            }, 3000);
        }).catch(function (error) {
            notifyError(error.response.data.message);
        });
    }
    const notifyError = (message) => toast.error(message,  {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message,  {
        duration: 6000,
    });

    const forgotUrl=webUrl+"changePassword";
    return (
        <>
            <div className="container" style={{paddingTop:"1%"}}>

                <div className="row">
                    <div className="col align-self-start">
                    </div>
                    <div className="col align-self-center">
                        <form className="Auth-form" onSubmit={(e) => submit(e)}>
                            <div className="Auth-form-content">
                                <div className="Auth-form-title" >
                                    <img  src={cms} style={{width: "100px", height: "auto"}} /><br />
                                    <h6> Sign in to CMMS </h6>
                                </div>
                                <div className="form-group mt-3">
                                    <label>Email address</label>
                                    <input onChange={(e) => handle(e)} id="email" value={data.email}
                                           type="email"
                                           className="form-control mt-1"
                                           title="Enter Your Email Address"
                                           placeholder="Enter Your Email Address"
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Password</label>
                                    <input onChange={(e) => handle(e)} id="password" value={data.password}
                                           type="password"
                                           className="form-control mt-1"
                                           title="Enter your password Account"
                                           placeholder="Enter your password Account"
                                    />
                                </div>
                                <div className="d-grid gap-2 mt-3">
                                    <button type="submit" className="btn btn-primary" title="Submit">
                                        Submit
                                    </button>
                                </div>
                                <p className="forgot-password text-center text-right mt-2">
                                     <a className="link-primary" title="forgot Password" target="_blank" href={forgotUrl}>Forgot Password</a>
                                </p>
                            </div>
                        </form>
                    </div>
                    <div className="col align-self-end">
                    </div>
                </div>

            </div>
            <div className="row "  id="footer">
                <div>

                    <div className="col align-self-start">
                    </div>
                    <div className="col align-self-center ">
                       <a target="_blank" href="https://www.unicef.org"> <img  src={UNICEF} alt="Unicef " height="30" width="auto" /></a> &nbsp;&nbsp;&nbsp;&nbsp;
                        <a target="_blank" href="https://ariadelta.af/"><img src={ARIADELTA} alt="Aria Delta" height="40" width="auto" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a target="_blank" href="https://www.wadan.org"><img src={WADAN} alt="Wadan " height="40" width="auto" /></a>

                        <div className="copyright p-3  " style={{fontSize:"8pt"}}>
                            Copyright &copy; {2022}  by Aria Delta Consulting Group<br/>
                            All rights reserved.
                        </div>
                    </div>

                    <div className="col align-self-end">
                    </div>

                </div>

            </div>
        </>
    );
}

export default Login;