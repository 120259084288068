import React, {useEffect, useRef, forwardRef, useState} from "react";
import "./Report.css"
import ReactToPrint from "react-to-print";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, Input
} from "reactstrap";
import {Form, FormGroup, Spinner} from "react-bootstrap";
import Axios from "axios";
import {mainUrl} from "../../api/axios";
import TableData from "./TableData";
import SearchItem from "./SearchItem";

function Reports() {

    const [reportType, setReportType] = React.useState(0);
    const [list, setList] = React.useState([]);
    const [isLoading, setIsLoading] = useState(0);
    const [headerData, setHeaderData] = React.useState({});

    const [periodDate, setPeriodDate] = React.useState({
        "startDate": "",
        "endDate": "",
        "workDays": "0",
    });
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader style={{backgroundColor: "#80808029"}}>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h3> Report </h3>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <SearchItem reportType={reportType} setReportType={setReportType} list={list} setList={setList} isLoading={isLoading} setIsLoading={setIsLoading} headerData={headerData}
                            setHeaderData={setHeaderData} periodDate={periodDate} setPeriodDate={setPeriodDate}
                            />
                        </Card>
                    </Col>
                </Row>
                <TableData reportType={reportType} setReportType={setReportType} list={list} setList={setList} isLoading={isLoading} setIsLoading={setIsLoading} headerData={headerData}
                setHeaderData={setHeaderData} periodDate={periodDate} setPeriodDate={setPeriodDate}
                />
            </div>
        </>
    );
}

export default Reports;
