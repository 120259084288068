
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

function Footer(props) {
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid ? true : false}>
        <Row>
          <nav className="footer-nav">
            <ul>

              <li>
                <a href="https://www.unicef.org/afghanistan/" target="_blank" className="" rel="noopener noreferrer">
                  Unicef</a>
              </li>
              <li>
                <a href="https://wadan.org/" target="_blank" className="" rel="noopener noreferrer">
                  WADAN</a>
              </li>
              <li>
                <a href="https://ariadelta.af" target="_blank" className="" rel="noopener noreferrer">
                  AriaDelta</a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto ">
            <div className="copyright"> Copyright © 2022 by Aria Delta Consulting Group
              All rights reserved.
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
