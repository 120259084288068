
// export const mainUrl ="http://10.2.0.2:8000/api/";
// export const mainUrl ="http://192.168.1.108:8000/api/";
export const mainUrl ="http://apiapp.ariadelta.af/api/";
export const webUrl ="http://apiapp.ariadelta.af/";
// export const webUrl ="http://192.168.1.108:8000/";
// export const webUrl ="http://10.2.0.2:8000/";

export let header = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+sessionStorage.getItem('token')
}

