
import Dashboard from "views/Dashboard.js";
import Settings from "views/Setting/Settings.js";
import UserPage from "views/Profile/Profile.js";
import Reports from "views/Report/Reports.js";

var routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    title:'A Dashboard is a management information system (MIS) that allows executives and other stakeholders to define targets and monitor progress of key performance indicators (KPI).',
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "",
  },
  {
    path: "/profile",
    name: "userProfile",
    title:'A user profile is a collection of settings and information associated with a user.',
    icon: "nc-icon nc-single-02",
    component: UserPage,
    layout: "",
  },
  {
    path: "/Setting",
    name: "setting",
    title:'setting is an adjustment in a software program that changes it to the user\'s preference. ',
    icon: "nc-icon nc-settings",
    component: Settings,
    layout: "",
  },
  {
    path: "/reports",
    name: "report",
    title:'Reporting is a key process in any organization and for every kind of project',
    icon: "nc-icon nc-book-bookmark",
    component: Reports,
    layout: "",
  }
];
export default routes;
