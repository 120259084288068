import React, {useEffect, useState} from "react";
import {Pie} from "react-chartjs-2";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import {Spinner} from "react-bootstrap"
import UNICEF from "./../assets/img/unicef-logo1.png";
import ARIADELTA from "./../assets/img/aria_delta.png";
import { useTranslation } from 'react-i18next';

import {
    dashboardEmailStatisticsChart
} from "variables/charts.js";
import Axios from "axios";
import {mainUrl} from "../api/axios";
import i18n from "i18next";

function Dashboard() {
    const { t, i18n } = useTranslation();

    const [maleCount, setMaleCount] = useState(1);
    const [femaleCount, setFemaleCount] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [adminCount, setAdminCount] = useState(0);
    const [appCount, setAppCount] = useState(0);
    const [locationCount, setLocationCount] = useState(0);
    const [positionCount, setPositionCount] = useState(0);

    useEffect(() => {
        loadData();

    },[]);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    function getPositions() {
        return Axios.get(mainUrl + "positionStatus",{headers});
    }
    function getLocations() {
        return Axios.get(mainUrl + "locationStatus",{headers});
    }
    function getUsers() {
        return Axios.get(mainUrl + "userStatus",{headers});
    }
    function getGenders() {
        return Axios.get(mainUrl + "genderStatus",{headers});
    }
    const loadData= async()=>{
       await Promise.all([getLocations(),getPositions(),getUsers(),getGenders()])
            .then(function (results) {
                setPositionCount(results[1].data);
                setLocationCount(results[0].data);

                // const lists = results[2].data
                results[2].data.map((e)=>{
                    if(e.user_type_id===2){
                        setAdminCount(e.total);
                    }else if(e.user_type_id===1){
                        setAppCount(e.total);
                    }
                });

                // const li = results[3].data
                results[3].data.map((e)=>{
                    if(e.gender_id===1){
                        setMaleCount(e.total);
                    }else if(e.gender_id===2){
                        setFemaleCount(e.total);
                    }
                });
                setIsLoading(true);
            }).catch(function (thrown) {
               if (Axios.isCancel(thrown)) {
                   console.log('Request canceled', thrown.message);
               } else {
                   // handle error
               }
           });;

    }

    return (
        <>
            <div className="content">
                {isLoading===false?(
                    <div className="text-center">
                        <Spinner  animation="border" variant="info" />
                    </div>
                ):(
                    <div>
                        <Row>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-user-run text-warning"/>
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category"> {t('totalAdminUser')}</p>
                                            <CardTitle tag="p">{adminCount}</CardTitle>
                                            <p/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr/>
                                <div className="stats">
                                    <i className="fas fa-sync-alt"/> Total Active User : {adminCount}
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-app text-danger"></i>
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Total App User</p>
                                            <CardTitle tag="p">{appCount}</CardTitle>
                                            <p/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr/>
                                <div className="stats">
                                    <i className="fas fa-sync-alt"/> Total App User : {appCount}
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-money-coins text-success"/>
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Total Branch</p>
                                            <CardTitle tag="p">{locationCount}</CardTitle>
                                            <p/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr/>
                                <div className="stats">
                                    <i className="far fa-calendar"/> Total Active Branch :{locationCount}
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-vector text-danger"/>
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Total Position</p>
                                            <CardTitle tag="p">{positionCount}</CardTitle>
                                            <p/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr/>
                                <div className="stats">
                                    <i className="far fa-clock"/> Total Active Position : {positionCount}
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    </Row>
                        <Row>
                        <Col md="6">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Gender Statistics</CardTitle>
                                    <p className="card-category">Last Campaign Performance </p>
                                </CardHeader>
                                <CardBody style={{height: "466px"}}>
                                    <Pie
                                        data={(canvas) => {
                                            return {
                                                labels: [1, 2, 3],
                                                datasets: [
                                                    {
                                                        label: "Gender",
                                                        pointRadius: 0,
                                                        pointHoverRadius: 0,
                                                        backgroundColor: ["#ffc107", "#007bff"],
                                                        borderWidth: 0,
                                                        data: [maleCount, femaleCount],
                                                    },
                                                ],
                                            };
                                        }}
                                        options={dashboardEmailStatisticsChart.options}
                                    />
                                </CardBody>
                                <CardFooter>
                                    <div className="legend">
                                        <i className="fa fa-circle text-primary"/> Female User {femaleCount}
                                         , <i className="fa fa-circle text-warning"/> Male User {maleCount}
                                    </div>
                                    <hr/>
                                    <div className="stats">
                                        <i className="fa fa-calendar"/> Total App user {femaleCount+maleCount}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Unicef</CardTitle>
                                    <p className="card-category"> Discover UNICEF's work for every child, everywhere.</p>
                                </CardHeader>
                                <CardBody style={{height: "366px"}}>
                                    <img src={UNICEF} alt="Unicef " />
                                </CardBody>
                                <CardFooter>
                                    <hr/>
                                    <div className="stats" style={{textAlign: "justify"}}>
                                        <i className="fa fa-calendar"/> UNICEF works in over 190 countries and territories to save children's lives,
                                        to defend their rights, and to help them fulfil their potential, from early childhood through adolescence. And we never give up.

                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Aria Delta</CardTitle>
                                    <p className="card-category"> Our Mission is to be the leading provider of ethical, ecological and result oriented business consulting services in Afghanistan..</p>
                                </CardHeader>
                                <CardBody style={{height: "266px"}}>
                                    <img src={ARIADELTA}  alt="Aria Delta "/>
                                </CardBody>
                                <CardFooter>
                                    <hr/>
                                    <div className="stats" style={{textAlign: "justify"}}>
                                        <i className="fa fa-calendar"/> Aria Delta Consulting Group was established by a team of professional consultants,
                                        financial analysts, training masters, examinations experts, study abroad consultants and procurement specialists.

                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                    </Row>
                    </div>
                 )}
            </div>
        </>
    );
}

export default Dashboard;
