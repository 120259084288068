import "./../../assets/css/bootstrap.min.css"
import React, {useEffect, useState} from "react";
import {Col,Row} from "react-bootstrap";
import {CardHeader, CardTitle, Input, Card} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../api/axios";
import toast, {Toaster} from 'react-hot-toast';
import AddProfile from "./AddProfile";
import ListProfile from "./ListProfile";
import SearchProfile from "./SearchProfile";

function Profile() {

    const [provinceList, setProvinceList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [list, setList] = React.useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [localImageProfile, setLocalImageProfile] = useState("");

    useEffect(() => {
        loadData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    function getPositions() {
        return Axios.get(mainUrl + "getPosition",{headers});
    }
    function getLocations() {
        return Axios.get(mainUrl + "getLocation",{headers});
    }
    function getProvinces() {
        return Axios.get(mainUrl + "getProvince",{headers});
    }
    function getProfiles() {
        return Axios.get(mainUrl + "getProfile",{headers});
    }
    const loadData= async()=>{
        await Promise.all([getPositions(),getLocations(),getProvinces(),getProfiles()])
            .then(function (results) {
                setPositionList(results[0].data);
                setLocationList(results[1].data);
                setProvinceList(results[2].data);
                setList(results[3].data);
                setIsLoading(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });;
    }


    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">

                <div>
                    <Row>
                        <Col md="12">
                            <Card className="card-user">
                                <CardHeader style={{backgroundColor: "#80808029"}}>
                                    <CardTitle className="mr-4 ml-4">
                                        <div className="row">
                                            <div className="ml-right">
                                                <h3> User Profile </h3>
                                            </div>
                                        </div>
                                    </CardTitle>
                                </CardHeader>
                                <SearchProfile setLocalImageProfile={setLocalImageProfile} localImageProfile={localImageProfile} isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal} setShowModal={setShowModal} setList={setList} provinceList={provinceList} locationList={locationList}
                                            positionList={positionList} headers={headers} notifyError={notifyError} notifySuccess={notifySuccess}/>
                            </Card>
                        </Col>
                    </Row>
                    <AddProfile setLocalImageProfile={setLocalImageProfile} localImageProfile={localImageProfile} isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal} setShowModal={setShowModal} setList={setList} provinceList={provinceList} locationList={locationList}
                                positionList={positionList}  headers={headers} notifyError={notifyError} notifySuccess={notifySuccess}/>
                    <ListProfile setLocalImageProfile={setLocalImageProfile} localImageProfile={localImageProfile} isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal} setShowModal={setShowModal} setList={setList} list={list}  headers={headers} notifyError={notifyError}
                                 notifySuccess={notifySuccess}/>
                </div>

            </div>
        </>
    );


}


export default Profile;
