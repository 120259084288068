import React from "react";
import Axios from "axios";
import {mainUrl} from "../../api/axios";
import {Button, Col, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";

function ListProfile(props) {

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteProfile", {
            id: recordId
        },{headers}).then(res => {
            props.notifySuccess("Successfully deleted");
            const lists = res.data;
            props.setList(lists);
        }).catch((error) => {
            props.notifyError(error.response.data.message);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }

    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    let rowNo = 1;
    return (
        <>
            <DeleteItem/>
            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2> User Profile </h2>
                                    </div>
                                    <div className="ml-end">
                                        <Button title="Add New Record" className="btn-success" variant="primary"
                                                onClick={() => {
                                                    props.setShowModal(true);
                                                    props.setLocalImageProfile("");
                                                    sessionStorage.setItem("image", "");
                                                }}>
                                            <span className="nc-icon nc-simple-add"/>
                                        </Button>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>
                        {props.isLoading === false ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="info"/>
                            </div>
                        ) : (
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>image</th>
                                        <th>Employee Id</th>
                                        <th>Email</th>
                                        <th>AppLogin Status</th>
                                        <th>Position</th>
                                        <th>Province</th>
                                        <th>Location</th>
                                        <th className="text-right">Status</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {props.list.map((e, index) => (
                                        <tr key={index}>
                                            <td>{rowNo++}</td>
                                            <td>
                                                <img width="64" height="auto" src={e.image} style={{
                                                    borderRadius: "50%",
                                                    backgroundColor: "#8080805e!important"
                                                }}/>

                                            </td>
                                            <td style={{width: "10%"}}> EMP - {e.id}</td>
                                            <td style={{width: "10%"}}>{e.email}</td>
                                            <td style={{width: "10%"}}>
                                                {(e.app_user_status === 1 && e.user_type_id == '1') &&
                                                <span className="badge badge-success ">SingIn</span>
                                                }
                                                {(e.app_user_status === 0 && e.user_type_id == '1') &&
                                                <span className="badge badge-danger ">Pending</span>
                                                }
                                                {(e.user_type_id == '2') &&
                                                <span className="badge badge-info ">ADMIN</span>
                                                }

                                            </td>
                                            <td style={{width: "15%"}}>{e.position_name}</td>
                                            <td style={{width: "15%"}}>{e.province_name}</td>
                                            <td style={{width: "15%"}}>{e.location_name} </td>
                                            <td style={{width: "10%"}}>
                                                {e.status_id === 1 &&
                                                <span className="badge badge-primary ">Active</span>
                                                }
                                                {e.status_id === 2 &&
                                                <span className="badge badge-warning ">Non Active</span>
                                                }

                                            </td>
                                            <td style={{whiteSpace: "nowrap"}}>
                                                <Button title="Delete Record" onClick={() => {
                                                    setRecordId(e.id);
                                                    setShowDeleteModal(true);
                                                }} className="btn-round btn-danger"
                                                        color="danger" type="Button">
                                                    <span className="nc-icon nc-simple-delete"/>
                                                </Button>&nbsp;
                                                <Button title="Update & Detail View Record" onClick={async () => {
                                                    await props.setShowModal(true);
                                                    document.getElementById('ids').value = e.id;
                                                    document.getElementById('employee_ids').value = 'EMP - ' + e.id;
                                                    document.getElementById('name').value = e.name;
                                                    document.getElementById('father_name').value = e.father_name;
                                                    document.getElementById('email').value = e.email;
                                                    document.getElementById('gender_id').value = e.gender_id;
                                                    document.getElementById('user_type_id').value = e.user_type_id;
                                                    document.getElementById('salary').value = e.salary;
                                                    document.getElementById('attachment').value = e.attachment;
                                                    document.getElementById('status_id').value = e.status_id;
                                                    document.getElementById('image').value = e.image;
                                                    sessionStorage.setItem("image", e.image);
                                                    props.setLocalImageProfile(e.image);
                                                    document.getElementById('province_id').value = e.province_id;
                                                    document.getElementById('position_id').value = e.position_id;
                                                    document.getElementById('location_id').value = e.location_id;
                                                }} className="btn-round" color="success" type="Button">
                                                    <span className="nc-icon nc-sound-wave"/>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </Table>
                            </CardBody>
                        )
                        }
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListProfile;