import React, {useEffect, useState} from "react";
import ImageSelect from "./utils/imageSelect";
import {Button, Col, FormGroup, Modal, Row} from "react-bootstrap";
import image from "../../assets/img/image.png";
import {Input} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../api/axios";

function AddProfile(props) {

    const [showUploadImage, setShowUploadImage] = useState(false);
    useEffect(() => {
        setTimeout(() => props.setLocalImageProfile(sessionStorage.getItem("image")), 1000);
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const addProfile = async (e) => {
        e.preventDefault();
        const f = e.target;
        await Axios.post(mainUrl + "addProfile", {
            id: f.ids.value,
            employee_id: f.employee_ids.value,
            name: f.name.value,
            father_name: f.father_name.value,
            email: f.email.value,
            gender_id: f.gender_id.value,
            user_type_id: f.user_type_id.value,
            province_id: f.province_id.value,
            location_id: f.location_id.value,
            position_id: f.position_id.value,
            salary: f.salary.value,
            attachment: f.attachment.value,
            status_id: f.status_id.value,
            image: f.image.value
        }, {headers}).then(res => {
            if (f.ids.value == 0) {
                props.notifySuccess(' Added Successfully.');
            } else {
                props.notifySuccess(' Updated Successfully.');
            }
            props.setList(res.data);
            props.setShowModal(false);
        }).catch(function (error) {
            props.notifyError(error.response.data.message);
            props.setShowModal(false);
        });
    };
    const po=props.localImageProfile;
    return (
        <>
            {showUploadImage == true && <ImageSelect setLocalImageProfile={props.setLocalImageProfile}/>}
            <Modal show={props.showModal} backdrop="static" size="xl" onHide={() => props.setShowModal(false)}>
                <form onSubmit={(e) => addProfile(e)}>
                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile">
                            New Profile
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <div className=" btn"
                                 style={{position: "relative", textAlign: "center", color: "white"}} alt="Image "
                                 title="Image">
                                <img src={props.localImageProfile != "" ? props.localImageProfile : image}
                                     style={{borderRadius: "50%", backgroundColor: "#8080805e!important"}}
                                     onClick={() => {
                                         if (!showUploadImage) {
                                             setShowUploadImage(true);
                                         } else {
                                             setShowUploadImage(false);
                                         }
                                     }} width="125" height="125"/>
                                <div style={{
                                    position: "absolute",
                                    top: "89%",
                                    left: "50%",
                                    transform: "translate(-50%, 50%)",
                                    whiteSpace: "nowrap"
                                }} className="text-primary">Select Image
                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="image" id="image" value={props.localImageProfile}/>
                        <div>
                            <Row className="pr-3">
                                <Col md="6">
                                    <FormGroup>
                                        <label>User Id</label>
                                        <Input type="hidden" name="ids" id="ids"/>
                                        <Input name="employee_ids" id="employee_ids"
                                               placeholder="Employee Id" type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Name <span className="text text-danger">*</span></label>
                                        <Input required name="name" id="name" required placeholder="name"
                                               title="name" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Father Name <span className="text text-danger">*</span></label>
                                        <Input required name="father_name" id="father_name"
                                               placeholder="Father Name" title="Father Name"
                                               type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Gender <span className="text text-danger">*</span></label>
                                        <select required name="gender_id" id="gender_id" required
                                                className="form-control" title="Gender">
                                            <option value=""></option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                        </select>

                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Active <span className="text text-danger">*</span></label>
                                        <select required name="status_id" id="status_id" required
                                                className="form-control" title="Status Type Id">
                                            <option value="1">Active</option>
                                            <option value="2">Non-Active</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label> Email address <span
                                            className="text text-danger">*</span></label>
                                        <Input required name="email" id="email" placeholder="Email Address"
                                               type="email" title="Email Address"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Province <span className="text text-danger">*</span></label>
                                        <select required name="province_id" id='province_id'
                                                className="form-control" title="Select Province">
                                            <option value=""></option>
                                            {
                                                props.provinceList.map((e, index) => (
                                                    <option key={index}
                                                            value={e.id}>{e.name}</option>))
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Location <span className="text text-danger">*</span></label>
                                        <select required name="location_id" id='location_id'
                                                className="form-control" title="Select Location">
                                            <option value=""></option>
                                            {props.locationList.map((e, index) => (
                                                <option key={index}
                                                        value={e.id}>{e.location_name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Position <span className="text text-danger">*</span></label>
                                        <select required name="position_id" id='position_id'
                                                className="form-control" title="Select position">
                                            <option value=""></option>
                                            {props.positionList.map((e) => (
                                                <option key={e.id}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>User Type <span className="text text-danger">*</span></label>
                                        <select required name="user_type_id" id='user_type_id'
                                                className="form-control" title="Select User Type">
                                            <option value="1">App User</option>
                                            <option value="2">Admin User</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Attachment <span className="text text-danger">*</span></label>
                                        <input name="attachment" id='attachment' type="file" title="Attach File"
                                               className="form-control"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label> Salary <span className="text text-danger">*</span></label>
                                        <Input required name="salary" id='salary' placeholder="Salary" type="Number"
                                               title="Salary"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button
                            title="Close"
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() => props.setShowModal(false)}>
                            Close
                        </Button>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" type="submit">
                            Submit
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
}

export default AddProfile;